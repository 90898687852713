<template>
  <div class="flex-1 xl:pt-12 xl:mx-24 <xl:mx-[28px] relative">
    <CountDown></CountDown>
    <div class="section">
      <h3 class="font-normal xl:text-3xl xl:mt-24 <xl:text-[30px] <xl:mt-[110px]">Complete your payment</h3>
      <h4 class="upi-id xl:text-lg xl:mt-7 <xl:text-[18px] <xl:mt-[28px]">Entered UPI ID: {{ upiId }}</h4>
      <div class="bg-$gray-bgcolor text-$gray-text rounded-[26PX] xl:px-7.5 xl:py-6 xl:mt-3 xl:text-base <xl:px-[30px] <xl:py-[25px] <xl:mt-[12px] <xl:text-[16px] !leading-snug">
        <div>Step 1: Go to the UPI APP</div>
        <br />
        <div class="break-words">Step2: Check pending requests and approve payment by entering UPI PIN</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {getPaymentResult} from "@/api/payment";
import CountDown from "@/components/CountDown.vue";

let route = useRoute();
let router = useRouter();

// 交易数据
let upiId = route.query.upiId;
let getResult;

const upiResult = async () => {
  try {
    let data = await getPaymentResult({},{noAlert:true});
    if ( data.status === "SUCCESS" || data.status === "FAILURE" || data.status === "CLOSED") {
      //已支付或支付失败
      clearTimeout(getResult);
      router.replace({
        path: "/paymentResult",
        // query: {
        //   type: "UPI",
        // },
      });
    } else {
      //未支付重试
      clearTimeout(getResult);
      getResult = setTimeout(() => {
        upiResult();
      }, 1000);
    }
  } catch (error) {
    //接口异常
    clearTimeout(getResult);
    getResult = setTimeout(() => {
      upiResult();
    }, 1000);
  }

};
upiResult();

</script>
